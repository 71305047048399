<mat-card>
    <mat-card-header>
        <mat-card-title>Hi, Sorry for the wait</mat-card-title>
        <mat-card-subtitle>Your table will be ready ASAP</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="100">
                There are {{ waitlistLength }} parties ahead of you
            </div>
            <div fxFlex="100">
                Would you like to wait?
            </div>
            <div fxFlex="100">
                <button mat-button color="primary" (click)="onAddToList()">Yes Please!</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>