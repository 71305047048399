<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)=onSignOut()>Sign Out</button>
</mat-menu>
<mat-toolbar color="primary">
  <span>Steel Bonnet Waitlist</span>
  <div *ngIf="user" fxFlex="100" fxLayoutAlign="end center">
    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</mat-toolbar>
<router-outlet></router-outlet>