<mat-card>
    <mat-card-header>
        <mat-card-title>Add Me To The List</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="row wrap">
            <mat-form-field fxFlex="100">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" (keydown)="onNameChange($event)">
            </mat-form-field>
            <p>Notify Me By:</p>
            <mat-form-field fxFlex="100">
                <mat-label>Email</mat-label>
                <input matInput type="email" placeholder="Email" (keydown)="onEmailChange($event)">
            </mat-form-field>
            <p>and/or</p>
            <mat-form-field fxFlex="100">
                <mat-label>Phone</mat-label>
                <input matInput placeholder="Phone" (keydown)="onPhoneChange($event)">
            </mat-form-field>
            <mat-form-field fxFlex="100">
                <mat-label>Number of Guests</mat-label>
                <input matInput type="number" placeholder="Guests" (keydown)="onGuestCountChange($event)">
            </mat-form-field>
        </div>
        <button mat-raised-button color="primary" [disabled]="!nameValid() || (!emailValid() && !phoneValid())"
            (click)="onSubmit()">Submit</button>
    </mat-card-content>
</mat-card>