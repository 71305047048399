import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  constructor(private _router: Router) {
  }

  ngOnInit(): void {
  }

  public onLoginSuccess(event: any): any {
    // for some reason the ngx-auth-firebaseui component does not navigate
    // to the loggedIn URL. Do that here
    //this._router.navigate(["/"])
  }

  public onLoginFail(event: any): void { }
}
