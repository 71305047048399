<mat-card>
    <mat-card-title>Add Party To Wait List</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form">
            <div fxLayout="row wrap" fxFlex="100">
                <mat-form-field fxFlex="100" appearance="fill">
                    <mat-label>Name</mat-label>
                    <input formControlName="contactName" matInput placeholder="Name">
                </mat-form-field>
                <mat-form-field fxFlex="100" appearance="fill">
                    <mat-label>Phone</mat-label>
                    <input formControlName="contactPhone" matInput placeholder="Phone">
                </mat-form-field>
                <mat-form-field fxFlex="100" appearance="fill">
                    <mat-label>Guests</mat-label>
                    <input formControlName="guestCount" matInput type="number">
                </mat-form-field>
                <mat-form-field fxFlex="100" appearance="fill">
                    <mat-label>Wait Time</mat-label>
                    <input formControlName="committedWait" matInput type="number">
                </mat-form-field>
                <div fxLayoutGap="10px">
                    <button mat-raised-button [disabled]='!form.valid' color="primary" [class.spinner]="addingGuest"
                        (click)="onSubmit()">Submit</button>
                    <button mat-raised-button color="primary" (click)="onCancel()"
                        [disabled]="addingGuest">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>