import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting-page',
  templateUrl: './waiting-page.component.html',
  styleUrls: ['./waiting-page.component.scss']
})
export class WaitingPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
