import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WaitlistService } from "../../services/waitlist.service";

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {
  private _waitlistLength: any;

  public get waitlistLength(): any {
    return this._waitlistLength;
  }
  public set waitlistLength(value: any) {
    this._waitlistLength = value;
  }

  constructor(private _router: Router, private _waitlistService: WaitlistService) { }

  ngOnInit(): void {
    this._waitlistLength = this._waitlistService.getWaitlistLength$()
      .subscribe((resp: any) => {
        console.log(resp);
        this.waitlistLength = resp;
      });
  }

  public onAddToList(): void {
    this._router.navigate(["/add-to-list"])
  }

}
