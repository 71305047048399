import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { FormBuilder, Validators } from '@angular/forms';
import { WaitlistService } from 'src/app/services/waitlist.service';
import { IWaitlistParty } from 'src/app/models/WaitlistParty';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-guest-form',
  templateUrl: './add-guest-form.component.html',
  styleUrls: ['./add-guest-form.component.scss']
})
export class AddGuestFormComponent implements OnInit {
  protected _form: any;
  protected _addingGuest: boolean = false;

  public get form(): any {
    return this._form;
  }
  public get addingGuest(): boolean {
    return this._addingGuest;
  }

  constructor(
    private _location: Location,
    private _fb: FormBuilder,
    private _waitistService: WaitlistService,
    private _dialogRef: MatDialogRef<AddGuestFormComponent>
  ) {
    this._form = _fb.group({
      contactName: ["", Validators.required],
      contactPhone: ["", Validators.required],
      guestCount: [, Validators.required],
      committedWait: [15, Validators.required]
    });
  }

  ngOnInit(): void { }

  public onSubmit(): void {
    let party: IWaitlistParty = {
      contactName: this._form.controls["contactName"].value,
      contactPhone: this._form.controls["contactPhone"].value,
      contactEmail: this._form.controls["contactPhone"].value,
      guestCount: this._form.controls["guestCount"].value,
      committedWait: this._form.controls["committedWait"].value,
      status: "Waiting"
    }

    this._addingGuest = true;

    this._waitistService.addWaitlistParty$(party).subscribe((resp: any) => {
      this._addingGuest = false;

      this._dialogRef.close()
    })
  }

  public onCancel(): void {
    this._dialogRef.close();
  }
}
