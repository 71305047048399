<div *ngIf="loading" style="height: 100vh;" fxLayoutAlign="center center">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
</div>
<!-- Table manager -->
<!-- simpleApp
<mat-card>
    <mat-card-content>
        <mat-table [dataSource]="tableDS">
            <ng-container matColumnDef="table">
                <mat-header-cell *matHeaderCellDef>Table</mat-header-cell>
                <mat-cell *matCellDef="let table">{{ table.name }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let table; columns: displayedColumns;">
            </mat-row>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                <mat-cell *matCellDef="let table">{{ table.status }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="capacity">
                <mat-header-cell *matHeaderCellDef>Capacity</mat-header-cell>
                <mat-cell *matCellDef="let table">{{ table.capacity }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="party">
                <mat-header-cell *matHeaderCellDef>Party</mat-header-cell>
                <mat-cell *matCellDef="let table">{{ table.partyId }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="changeStatus">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let table">
                    <button *ngIf="table.status === 'Free'" mat-raised-button color="primary"
                        (click)="onOccupyTable(table)">Occupy</button>
                    <button *ngIf="table.status === 'Occupied'" mat-raised-button color="primary"
                        (click)="onFreeTable(table)">Free</button>
                    <button *ngIf="table.status === 'Pending'" mat-raised-button color="primary"
                        (click)="onReleaseTable(table)">Release</button>
                </mat-cell>
            </ng-container>
        </mat-table>
    </mat-card-content>
</mat-card>
-->
<!-- Waitlist manager  -->
<mat-card *ngIf="!loading">
    <div fxLayout="row wrap">
        <div fxFlex="100" fxLayoutAlign="end">
            <button mat-icon-button matTooltip="Add guest to waitlist" (click)="onAddGuest()">
                <mat-icon>person_add_alt_1</mat-icon>
            </button>
        </div>
        <mat-card-content *ngIf="waitlistLength == 0">
            <h4>No one currently waiting</h4>
        </mat-card-content>
        <mat-card-content *ngIf="waitlistLength > 0" fxFlex="100">
            <div class="table-responsive">
                <mat-table [dataSource]="waitlistDS">
                    <mat-header-row *matHeaderRowDef="waitlistColumns"></mat-header-row>
                    <mat-row *matRowDef="let party; columns: waitlistColumns;">
                    </mat-row>
                    <!-- columns-->
                    <ng-container matColumnDef="contactName">
                        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                        <mat-cell *matCellDef="let party">{{ party.contactName }}</mat-cell>
                    </ng-container>
                    <!--
            <ng-container matColumnDef="contactEmail">
                <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                <mat-cell *matCellDef="let party">{{ party.contactEmail }}</mat-cell>
            </ng-container>
        -->
                    <ng-container matColumnDef="contactPhone">
                        <mat-header-cell *matHeaderCellDef>Phone</mat-header-cell>
                        <mat-cell *matCellDef="let party">{{ party.contactPhone }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="partyOf">
                        <mat-header-cell *matHeaderCellDef>Guests</mat-header-cell>
                        <mat-cell *matCellDef="let party">{{ party.guestCount }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="time">
                        <mat-header-cell *matHeaderCellDef>Signup</mat-header-cell>
                        <mat-cell *matCellDef="let party">{{ this.formatEpochTime(party.timestamp) }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="committedWait">
                        <mat-header-cell *matHeaderCellDef>Wait</mat-header-cell>
                        <mat-cell *matCellDef="let party">{{ party.committedWait }} mins</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let party">{{ party.status }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="table">
                        <mat-header-cell *matHeaderCellDef>Table</mat-header-cell>
                        <mat-cell *matCellDef="let party">{{ party.tableId }}</mat-cell>
                    </ng-container>
                    <!-- simpleApp
                <ng-container matColumnDef="checkIn">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let party">
                        <button [disabled]="party.status === 'Waiting'" mat-raised-button color="primary"
                            (click)="onCheckIn(party)">Check In</button>
                    </mat-cell>
                </ng-container>
            -->
                    <ng-container matColumnDef="removeParty">
                        <mat-header-cell fxFlex="10" fxLayoutAlign="end center" *matHeaderCellDef></mat-header-cell>
                        <mat-cell fxFlex="10" fxLayoutAlign="end center" *matCellDef="let party">
                            <button mat-icon-button (click)="onRemoveParty(party)"
                                [class.spinner]="removingParty && (currentParty.contactPhone == party.contactPhone)">
                                <mat-icon>person_remove</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="notifyParty">
                        <mat-header-cell fxFlex="10" fxLayoutAlign="end center" *matHeaderCellDef></mat-header-cell>
                        <mat-cell fxFlex="10" fxLayoutAlign="end center" *matCellDef="let party">
                            <button mat-icon-button (click)="onNotifyParty(party)"
                                [class.spinner]="notifyingParty && (currentParty.contactPhone == party.contactPhone)">
                                <mat-icon>notifications</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="checkIn">
                        <mat-header-cell fxFlex="10" fxLayoutAlign="end center" *matHeaderCellDef></mat-header-cell>
                        <mat-cell fxFlex="10" fxLayoutAlign="end center" *matCellDef="let party">
                            <button mat-icon-button (click)="onCheckInSimple(party)"
                                [class.spinner]="checkingIn && (currentParty.contactPhone == party.contactPhone)">
                                <mat-icon>check</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                </mat-table>
            </div>
        </mat-card-content>
    </div>
</mat-card>