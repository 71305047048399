import { Component, OnInit } from '@angular/core';
import { WaitlistService } from "../../services/waitlist.service"
import { catchError, mergeMapTo, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireMessaging } from "@angular/fire/messaging";
import { IWaitlistParty } from 'src/app/models/WaitlistParty';

@Component({
  selector: 'app-add-to-list-form',
  templateUrl: './add-to-list-form.component.html',
  styleUrls: ['./add-to-list-form.component.scss']
})
export class AddToListFormComponent implements OnInit {
  private _party: IWaitlistParty =
    {
      contactEmail: "",
      contactName: "",
      guestCount: 0,
      status: "Waiting"
    };

  constructor(private _router: Router, private _waitlistService: WaitlistService,
    private _afMessaging: AngularFireMessaging) { }

  ngOnInit(): void {
    this.requestPushPermission();
    this.listenForUpdates();
  }

  protected requestPushPermission() {
    this._afMessaging.requestToken
      .subscribe(
        (token) => {
          this._party.messageToken = token;
        },
        (error) => { console.error(error); },
      );
  }

  protected deleteToken() {
    this._afMessaging.getToken
      .pipe(mergeMap(token => this._afMessaging.deleteToken(token)))
      .subscribe(
        (token) => { console.log('Token deleted!'); },
      );
  }

  protected listenForUpdates(): void {
    /* simpleApp
    this._afMessaging.messaging.subscribe(
      (messaging: any) => {
        messaging.onMessageCallback = (payload: any) => {
          console.log("Messaging");
        };
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      });
      */
  }

  public onNameChange(event: any) {
    this._party.contactName = event.target.value;
  }

  public onEmailChange(event: any) {
    this._party.contactEmail = event.target.value;
  }

  public onPhoneChange(event: any) {
    this._party.contactPhone = event.target.value;
  }

  public onGuestCountChange(event: any) {
    this._party.guestCount = event.target.value + 1; // why do i have to add 1?
  }

  public nameValid(): boolean {
    return this._party.contactName && this._party.contactName.length > 1;
  }

  public emailValid(): boolean {
    return this._party.contactEmail && this._party.contactEmail.length > 0;
  }
  public phoneValid(): boolean {
    return this._party.contactPhone && this._party.contactPhone.length > 0;
  }

  public onSubmit(): void {
    this._waitlistService.addWaitlistParty$(this._party).pipe(
      catchError((err: any) => {
        console.log("Error" + err);
        return of(err);
      })
    ).subscribe((resp: any) => {
      this._router.navigate(["/waiting"]);
    });
  }
}