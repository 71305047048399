import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/functions';
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFirestoreModule, SETTINGS } from "@angular/fire/firestore"
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio'
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatMenuModule } from "@angular/material/menu";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StartPageComponent } from './components/start-page/start-page.component';
import { AddToListFormComponent } from './components/add-to-list-form/add-to-list-form.component';
import { NgxAuthFirebaseUIModule, NgxAuthFirebaseUIConfig } from 'ngx-auth-firebaseui';

import { environment } from "../environments/environment";
import { WaitingPageComponent } from './components/waiting-page/waiting-page.component';
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { AddGuestFormComponent } from './components/add-guest-form/add-guest-form.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const ngxAuthFirebaseUIConfig: NgxAuthFirebaseUIConfig = {
  enableFirestoreSync: true, // enable/disable autosync users with firestore
  toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
  toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
  authGuardFallbackURL: '/login', // url for unauthenticated users - to use in combination with canActivate feature on a route
  authGuardLoggedInURL: '/admin-page', // url for authenticated users - to use in combination with canActivate feature on a route
  passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
  passwordMinLength: 8, // Password length min/max in forms independently of each componenet min/max.
  // Same as password but for the name
  nameMaxLength: 50,
  nameMinLength: 2,
  // If set, sign-in/up form is not available until email has been verified
  // Plus protected routes are still protected even though user is connected.
  guardProtectedRoutesUntilEmailIsVerified: false,
  enableEmailVerification: false, // default: true
};

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    StartPageComponent,
    AddToListFormComponent,
    WaitingPageComponent,
    AdminPageComponent,
    AddGuestFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxAuthFirebaseUIModule.forRoot(environment.firebaseConfig, () => "Spangle App Template", ngxAuthFirebaseUIConfig),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    AngularFirestoreModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatMenuModule
  ],
  providers: [
    /*
    {
      provide: ORIGIN,
      useValue: "http://localhost:5001"
    },
    {
      provide: SETTINGS,
      useValue: environment.production ? undefined : {
        host: "localhost:5002",
        ssl: false
      }
    }
    */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
