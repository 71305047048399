import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
/*
import { StartPageComponent } from './components/start-page/start-page.component';
import { AddToListFormComponent } from './components/add-to-list-form/add-to-list-form.component';
import { WaitingPageComponent } from './components/waiting-page/waiting-page.component';
*/
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { AddGuestFormComponent } from './components/add-guest-form/add-guest-form.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LoggedInGuard } from 'ngx-auth-firebaseui';

const routes: Routes = [
  /* simpleApp 
  {
    path: "add-to-list",
    component: AddToListFormComponent
  },
  {
    path: "waiting",
    component: WaitingPageComponent
  },
  {
    path: "admin",
    component: AdminPageComponent
  },
  {
    path: "**",
    component: StartPageComponent
  }
  */
  {
    path: "login",
    component: LoginPageComponent
  },
  {
    path: "admin",
    component: AdminPageComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "**",
    component: AdminPageComponent,
    canActivate: [LoggedInGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
