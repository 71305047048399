import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private _db: AngularFirestore) { }

  // TODO: remember in production to protect API key by restricting origin
  public tables$(): Observable<any> {
    return this._db.collection("steel-bonnet-brewing")
      .doc("tap-room")
      .collection("tables")
      .snapshotChanges().pipe(
        map(actions => {
          return actions.map(action => {
            const table = action.payload.doc.data()
            console.log(JSON.stringify(table, undefined, 4))
            return table;
          })
        })
      )
  }

  public waitlist$(): Observable<any> {
    return this._db.collection("steel-bonnet-brewing")
      .doc("tap-room")
      .collection("waitlist")
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(action => {
            const party = action.payload.doc.data()
            return party;
          })
        })
      )
  }
}
