import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";

import { WaitlistService } from 'src/app/services/waitlist.service';
import { IWaitlistParty } from 'src/app/models/WaitlistParty';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddGuestFormComponent } from '../add-guest-form/add-guest-form.component';
import { timer, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

export class NotifyTimer {
  private _party: IWaitlistParty;
  private _cancel$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _dueTimeMins: number;

  public get party(): IWaitlistParty {
    return this._party;
  }

  constructor(party: IWaitlistParty, dueTimeMins: number) {
    this._party = party;
    this._dueTimeMins = dueTimeMins;
  }

  public start$(): Observable<number> {
    let dueTimeMs: number = this._dueTimeMins * 60 * 1000;

    return timer(dueTimeMs)
      .pipe(
        takeUntil(this._cancel$.pipe(skip(1)))
      );
  }

  public cancel(): void {
    this._cancel$.next(true);
  }
}

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {
  private _tableDS: MatTableDataSource<any>;
  private _waitlistDS: MatTableDataSource<IWaitlistParty>;
  private _displayedColumns: string[];
  private _waitlistColumns: string[];
  private _loadingTables: boolean = false;
  private _loadingWaitlist: boolean = false;
  private _notifyingParty: boolean = false;
  private _checkingIn: boolean = false;
  private _removingParty: boolean = false;
  private _currentParty: IWaitlistParty = null;

  // getters & setters
  public get tableDS(): MatTableDataSource<any> {
    return this._tableDS;
  }
  public get displayedColumns(): string[] {
    return this._displayedColumns;
  }
  public get waitlistDS(): MatTableDataSource<IWaitlistParty> {
    return this._waitlistDS;
  }
  public get waitlistColumns(): string[] {
    return this._waitlistColumns;
  }
  public get loading(): boolean {
    return this._loadingTables || this._loadingWaitlist;
  }
  public get notifyingParty(): boolean {
    return this._notifyingParty;
  }
  public get removingParty(): boolean {
    return this._removingParty;
  }
  public get checkingIn(): boolean {
    return this._checkingIn;
  }
  public get waitlistLength(): number {
    return this._waitlistDS.data.length;
  }
  public get currentParty(): IWaitlistParty {
    return this._currentParty;
  }


  // constructor
  constructor(
    private _waitlistService: WaitlistService,
    private _admin: AdminService,
    private _router: Router,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  // angular lifecycle event handlers
  ngOnInit(): void {
    this._loadingTables = true;
    this._loadingWaitlist = true;
    // subscribe to datastore changes
    this._admin.tables$().subscribe((tables: any[]) => {
      this._loadingTables = false;
      this._tableDS = new MatTableDataSource<any>(tables);
    });

    this._admin.waitlist$().subscribe((waitlist: any[]) => {
      let sortedWaitList: any[];

      sortedWaitList = waitlist.sort((party1: IWaitlistParty, party2: IWaitlistParty) => {
        if (party1.timestamp > party2.timestamp) {
          return 1;
        }
        else if (party1.timestamp < party2.timestamp) {
          return -1;
        }
        else {
          return 0;
        }
      });

      this._loadingWaitlist = false;

      this._waitlistDS = new MatTableDataSource<any>(sortedWaitList);
    })

    this._displayedColumns = [
      "table", "status", "capacity", "party", "changeStatus"
    ];

    this._waitlistColumns = [
      // simpleApp: "contactName", "contactEmail", "partyOf", "time", "status", "table", "checkIn"
      "contactName", "contactPhone", "partyOf", "time", "committedWait", "status", "removeParty", "notifyParty", "checkIn"
    ];
  }

  // view event handlers
  public onOccupyTable(table: any): void {
    let update: any = {
      tableId: table.tableId,
      status: "Occupied"
    };

    this._waitlistService.setTableStatus$(update)
      .subscribe((table: any) => {
        // TODO: stop spinner
      })
  }

  public onFreeTable(table: any): void {
    let update: any = {
      tableId: table.tableId,
      status: "Free"
    };

    this._waitlistService.setTableStatus$(update)
      .subscribe((table: any) => {
        // TODO: stop spinner
      })
  }

  public onReleaseTable(table: any): void {
    let update: any = {
      tableId: table.tableId,
      status: "Release"
    };

    this._waitlistService.setTableStatus$(update)
      .subscribe((table: any) => {
        // TODO: stop spinner
      })
  }

  public formatEpochTime(date: number): string {
    return moment.unix(date).format("hh:mm a");
  }

  public onCheckIn(party: IWaitlistParty): void {
    let tableUpdate: any = {
      tableId: party.tableId,
      status: "Occupied"
    };
    let waitlistUpdate: any = {
      contactEmail: party.contactEmail
    };

    this._waitlistService.removeWaitlistParty$(waitlistUpdate)
      .subscribe((resp: any) => {
        this._waitlistService.setTableStatus$(tableUpdate)
          .subscribe((table: any) => {
            // TODO: stop spinner
          });

        // TODO: stop spinner
      });
  }

  public onAddGuest(): void {
    let dialogRef: any;

    dialogRef = this._dialog.open(AddGuestFormComponent, {
      width: "400px",
      height: "450px"
    });
  }

  public onRemoveParty(party: IWaitlistParty): void {
    this._removingParty = true;
    this._currentParty = party;

    this._waitlistService.removeWaitlistParty$(party).subscribe((resp: any) => {
      this._removingParty = false;
      this._currentParty = null;
    });
  }

  public onNotifyParty(party: IWaitlistParty): void {
    this._notifyingParty = true;
    this._currentParty = party;

    this._waitlistService.notifyWaitlistParty$(party).subscribe((resp: any) => {
      let notifyTimer: NotifyTimer = new NotifyTimer(party, 3);

      this._notifyingParty = false;
      this._currentParty = null;

      notifyTimer.start$().subscribe(() => {
        // let snackBarRef: any = this._snackBar.open("Notification period has expired", "OK");
        this._waitlistService.setPartyStatus$(party, "Expired").subscribe(() => {
          console.log("Notify period expired");
        });
      });
    })
  }

  public onCheckInSimple(party: IWaitlistParty): void {
    this._checkingIn = true;
    // TODO: cancel notify timer. Should I move timers to server?

    this._waitlistService.partyCheckIn$(party).subscribe(() => {
      this._checkingIn = false;
    });
  }
}
