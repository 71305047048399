import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { IWaitlistParty, PartyWaitStatus } from '../models/WaitlistParty';

@Injectable({
  providedIn: 'root'
})
export class WaitlistService {

  constructor(private _fns: AngularFireFunctions) { }

  public getWaitlistLength$(): Observable<number> {
    let getWaitlistLength = this._fns.httpsCallable("onWaitlistGetLength");

    return getWaitlistLength({}).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        console.log(err);
        return of(err);
      })
    );
  }

  public addWaitlistParty$(party: any): Observable<number> {
    let addWaitlistParty = this._fns.httpsCallable("onWaitlistAddParty");

    return addWaitlistParty(party).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        console.log(err);
        return of(err);
      })
    );
  }

  public removeWaitlistParty$(party: any): Observable<number> {
    let removeWaitlistParty = this._fns.httpsCallable("onWaitlistRemoveParty");

    return removeWaitlistParty(party).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        console.log(err);
        return of(err);
      })
    );
  }

  public notifyWaitlistParty$(party: any): Observable<number> {
    let notifyWaitlistParty = this._fns.httpsCallable("onWaitlistNotifyContact");

    return notifyWaitlistParty(party).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((err) => {
        console.log(err);
        return of(err);
      })
    );
  }

  public getTables$(tableNames: string[]): Observable<any[]> {
    let getTables = this._fns.httpsCallable("onGetTables");

    return getTables(tableNames)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err) => {
          console.log(err);
          return of(err);
        })
      );
  }

  public getWaitlist$(): Observable<IWaitlistParty[]> {
    let getWaitlist = this._fns.httpsCallable("onGetWaitlist");

    return getWaitlist({})
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err) => {
          console.log(err);
          return of(err);
        })
      );
  }

  public setTableStatus$(table: any): Observable<any> {
    let setTableStatus = this._fns.httpsCallable("onSetTableStatus");

    return setTableStatus(table)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err) => {
          console.log(err);
          return of(err);
        })
      );
  }

  public setPartyStatus$(party: any, status: PartyWaitStatus): Observable<any> {
    let setPartyStatus = this._fns.httpsCallable("onSetPartyStatus");

    party.status = status;

    return setPartyStatus(party)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err) => {
          console.log(err);
          return of(err);
        })
      );
  }

  public partyCheckIn$(party: any): Observable<any> {
    let partyCheckIn = this._fns.httpsCallable("onPartyCheckIn");

    // TODO: cancel notify timer

    return partyCheckIn(party)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((err) => {
          console.log(err);
          return of(err);
        })
      );
  }
}
