import { Component, OnInit } from '@angular/core';
import { WaitlistService } from './services/waitlist.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private _user: firebase.User;

  public get user(): firebase.User {
    return this._user;
  }

  constructor(
    private _waitlistService: WaitlistService,
    private _afAuth: AngularFireAuth,
    private _router: Router
  ) {
    _afAuth.authState.subscribe((user: firebase.User) => {
      this._user = user;

      if (!user || user.isAnonymous) {
        this._router.navigate(["/login"]); // let router figure out which page to show
      }
      else {
        this._router.navigate(["/"]); // let router figure out which page to show
      }
    });
  }

  public ngOnInit() {
  }

  public onSignOut(): void {
    this._afAuth.signOut(); // TODO: check for errors
  }
}
